'use client';

import avatar from '@/assets/default-avatar.png';
import logo from '@/assets/workfocus-logo-small.svg';
import { auth as authService } from '@/src/api/service';
import { Avatar, DarkThemeToggle, Dropdown, DropdownItem, Navbar, NavbarBrand } from 'flowbite-react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { FiUsers } from 'react-icons/fi';
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2';

export default function Header() {
  const path = usePathname();
  const router = useRouter();

  const logout = async () => {
    await authService.logout(null);
    window.location.reload();
  };

  return (
    <Navbar
      fluid
      className="w-full border-b border-gray-700 bg-gray-700 p-0 dark:border-gray-700 dark:bg-gray-800 sm:p-0"
    >
      <div className="relative flex w-full flex-row flex-nowrap items-center justify-between p-3">
        <NavbarBrand as={Link} href="/" className="space-x-3">
          <Image src={logo} alt="WorkFocus Logo" height={32} priority width={32} className="min-w-[32px]" />
          <span className="hidden self-center whitespace-nowrap text-xl font-light text-gray-300 sm:block">
            Admin Console
          </span>
        </NavbarBrand>
        <nav className="flex w-full justify-center">
          <ul className="flex h-10 items-center -space-x-px text-sm">
            <li>
              <button
                aria-label="Select Business Executive role"
                className={`ms-0 flex items-center justify-center rounded-s-lg border border-e-0 border-gray-500 px-3 py-2 leading-tight dark:border-gray-600 ${
                  path == '/'
                    ? 'bg-gray-800 text-gray-200 dark:bg-gray-600'
                    : 'bg-gray-600 text-gray-300 hover:bg-gray-700 hover:text-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300'
                }`}
                onClick={() => router.push('/')}
              >
                <HiOutlineClipboardDocumentList className="min-w-3 lg:mr-2" />
                <span>Dashboard</span>
              </button>
            </li>
            <li>
              <button
                aria-label="Select Fusion Developer role"
                className={`flex items-center justify-center rounded-e-lg border border-gray-500 px-3 py-2 leading-tight dark:border-gray-600 ${
                  path == '/clients' || path.startsWith('/clients')
                    ? 'bg-gray-800 text-gray-200 dark:bg-gray-600'
                    : 'bg-gray-600 text-gray-300 hover:bg-gray-700 hover:text-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300'
                }`}
                onClick={() => router.push('/clients')}
              >
                <FiUsers className="min-w-3 lg:mr-2" />
                <span>Clients</span>
              </button>
            </li>
          </ul>
        </nav>
        <div className="ml-3 hidden dark:block">
          <DarkThemeToggle />
        </div>
        <div className="ml-3 dark:hidden">
          <DarkThemeToggle />
        </div>
        <Dropdown
          className="z-50 rounded"
          arrowIcon={false}
          inline
          label={
            <div className="ml-2 min-w-8">
              <Avatar alt="" img={avatar.src} rounded size="sm" />
            </div>
          }
        >
          {/*<DropdownHeader className="px-4 py-3">*/}
          {/*  <span className="block text-sm">Admin User</span>*/}
          {/*</DropdownHeader>*/}
          {/*<DropdownDivider />*/}
          <DropdownItem onClick={logout}>Sign Out</DropdownItem>
        </Dropdown>
      </div>
    </Navbar>
  );
}
