import { isValidJson } from '@/src/api';
import type { ApiResponse } from '@/src/core/rest-api/types';
import type { License, LicenseExtended } from '@/src/domain/entities';

export const license = {
  getAll: async (clientId: string, cookie: string | null): Promise<LicenseExtended[]> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/licenses?clientId=${clientId}`, {
      headers: {
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`,
        Cookie: `${cookie}`
      }
    });
    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<LicenseExtended[]> = JSON.parse(responseText);
        return (data.success && data.data) || [];
      }
    }

    return [];
  },
  create: async (license: Partial<LicenseExtended>): Promise<LicenseExtended | undefined> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/licenses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`
      },
      body: JSON.stringify(license)
    });

    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<LicenseExtended> = JSON.parse(responseText);
        return (data.success && data.data) || undefined;
      }
    }

    return undefined;
  },
  update: async (license: Partial<LicenseExtended>): Promise<LicenseExtended | undefined> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/licenses/${license.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`
      },
      body: JSON.stringify(license)
    });

    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<LicenseExtended> = JSON.parse(responseText);
        return (data.success && data.data) || undefined;
      }
    }

    return undefined;
  },
  updateUnverified: async (sourceLicense: LicenseExtended): Promise<LicenseExtended | undefined> => {
    const newLicense = {
      id: sourceLicense.id,
      type: sourceLicense.type,
      enabled: sourceLicense.enabled,
      fusionOrgId: sourceLicense.fusionOrgId,
      fusionTeamId: sourceLicense.fusionTeamId,
      workfrontPortfolioId: sourceLicense.workfrontPortfolioId,
      workfrontProgramId: sourceLicense.workfrontProgramId,
      workfrontTemplateId: sourceLicense.workfrontTemplateId,
      clientId: sourceLicense.clientId,
      aiSummaryEnabled: sourceLicense.aiSummaryEnabled,
      logMetricsEnabled: sourceLicense.logMetricsEnabled,
      workfrontBackupEnabled: sourceLicense.workfrontBackupEnabled,
      workfrontReuseBackupConfig: sourceLicense.workfrontReuseBackupConfig
    } as License;

    if (sourceLicense.fusionScenarioId) {
      newLicense.fusionScenarioId = sourceLicense.fusionScenarioId;
    }
    if (sourceLicense.fusionScenarioFolderId) {
      newLicense.fusionScenarioFolderId = sourceLicense.fusionScenarioFolderId;
    }
    if (sourceLicense.fusionConfigId) {
      newLicense.fusionConfigId = sourceLicense.fusionConfigId;
    }
    if (sourceLicense.workfrontConfigId) {
      newLicense.workfrontConfigId = sourceLicense.workfrontConfigId;
    }

    return license.update(newLicense);
  },
  delete: async (id: number): Promise<boolean> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/licenses/${id}`, {
      method: 'DELETE',
      headers: {
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`
      }
    });

    return response.ok;
  }
};
